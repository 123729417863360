<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка документа</p>
    </div>
    <div>
      <div class="row">
        <div class="form-group required">
          <ComponentInput
            v-model="card.name"
            label="Название"
            required
            :maxLength="1000"
            placeholder="Введите название"
          />
        </div>
      </div>
      <div class="row" v-if="!card.documentId">
        <div class="form-group">
          <label class="form-label">Файл</label>
          <FileUpload width="100%" :entity="card" @deleteFile="removeFile" prop-name="file" @uploaded="fileUploaded" />
        </div>
      </div>
      <div class="row" v-if="!card.fileId && (card.documentId || hasAdminAccess)">
        <label class="form-label">Документ</label>
        <div class="form-group d-flex align-items-center">
          <div class="input-wrapper">
            <ComponentInput
              :select2Options="documentList"
              v-model="card.documentId"
              type="select2"
              :select2Settings="select2SettingsWithAjax"
              :disabled="!hasAdminAccess"
            />
          </div>
          <div class="icon-wrapper" v-if="card.documentHasRtf">
            <IconComponent @click="downloadDoc()" name="download_transparent" title="Скачать документ" />
          </div>
          <div class="icon-wrapper" v-if="card.documentUniversalRef">
            <IconComponent @click="openDocumentRef()" name="docsIcon" title="Открыть документ" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group required">
          <label for="txtComment" class="form-label">Комментарий</label>
          <textarea v-model="card.comment" id="txtComment" class="form-control" rows="3"></textarea>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isCopy" id="chCopy" />
          <label for="chCopy">Копия</label>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';
  import runtimeConfig from '@/common/runtime-config';
  import IconComponent from '@/common/ui/IconComponent';

  import ComponentInput from '../../../common/components/ComponentInput';
  import FileUpload from '../../../common/components/FileUpload';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../../navigator/api/document';

  export default {
    name: 'DocumentCard',
    components: { FileUpload, ButtonStock, ComponentInput, IconComponent },
    data() {
      return {
        id: this.$route.params.id,
        card: {},
        documentList: [],
        select2SettingsWithAjax: {
          language: Constants.langRu,
          allowClear: true,
          minimumInputLength: 3,
          ajax: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            delay: 500,
            url: runtimeConfig.apiBaseUrl + '/api/navigatorDocument/searchExternal',
            dataType: 'json',
            data: function (params) {
              var query = {
                query: params.term,
              };
              return query;
            },
            processResults: function (responseData) {
              let data = responseData.map((item) => {
                return {
                  text: item.title,
                  id: item.id,
                };
              });
              const selectData = data.sort((a, b) => a.text.localeCompare(b.text));
              return {
                results: selectData,
              };
            },
            cache: true,
          },
        },
        hasAdminAccess: this.$store.getters['hasAdminAccess'],
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
            if (this.card.documentId) this.loadDocument();
          });
        }
      },
      loadDocument() {
        this.documentList.push({ id: this.card.documentId, text: this.card.documentTitle });
      },
      save() {
        console.log(this.document);
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        API.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({ name: 'NavigatorDocumentCard', params: { id: this.card.id, action: 'edit' } });
            } else {
              this.$router.push({ name: 'NavigatorDocuments' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      fileUploaded(e) {
        this.card.file = e.data;
        this.card.fileId = e.data ? e.data.id : null;
      },
      removeFile() {
        this.card.file = {};
        this.card.fileId = null;
      },
      close() {
        this.$router.push({ name: 'NavigatorDocuments' });
      },
      downloadDoc() {
        API.getRtf(this.card.documentId);
      },
      openDocumentRef() {
        window.open(this.card.documentUniversalRef, '_blank');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
  .icon-wrapper {
    margin: 5px 10px;
    & svg {
      stroke: black;
      & path {
        stroke: black;
      }
    }
  }
  .icon-wrapper:deep(svg path) {
    stroke: black;
    color: black;
  }
  .input-wrapper {
    min-width: 200px;
    width: 100%;
  }
</style>
